const PALETTE: {
    COLOR_BLUE: string;
    COLOR_RED: string;
    COLOR_YELLOW: string;
    COLOR_BUTTON: string;
    COLOR_BORDER: string;
    COLOR_ICON: string;
    COLOR_WHITE: string;
    COLOR_BLACK: string;
    COLOR_BACK: string;
    COLOR_BACKB1: string;
    COLOR_BACKB2: string;
    COLOR_SKY: string;
    COLOR_NAVY: string;
    COLOR_PURPLE: string;
    COLOR_PURPLE_W: string;
    COLOR_MAIN: string
} = (function () {
    return {
        COLOR_BLUE: '#228CE6',
        COLOR_RED: '#FF0D45',
        COLOR_YELLOW: '#F7E543',
        COLOR_BUTTON: '#2D5AFF',
        COLOR_BORDER: '#d3d3d3',
        COLOR_ICON: '#a4a4a4',
        COLOR_WHITE: '#ffffff',
        COLOR_BLACK: '#000000',
        COLOR_BACK: '#F0F0F7',
        COLOR_BACKB1: '#151515',
        COLOR_BACKB2: '#1B1B1B',
        COLOR_SKY: '#0E7FF4',
        COLOR_PURPLE_W: '#90a8f9',
        COLOR_NAVY: '#20202f',
        COLOR_PURPLE: '#462679',
        COLOR_MAIN: '#2dc6ab'
    }
}())

export {
    PALETTE,
}