"use client";
import Image from "next/image";
import styles from "./page.module.css";
import dynamic from "next/dynamic";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect, useRef } from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  MotionValue,
} from "framer-motion";
import { PALETTE } from "./_lib/constant/palette";
import Link from "next/link";
import Head from "next/head";

function useParallax(value: MotionValue<number>, distance: number) {
  return useTransform(value, [0, 1], [-distance, distance]);
}

function ImageMotion({ id }: { id: number }) {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, 600);
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });
  return (
    <section
      key={id}
      style={{
        height: "100vh",
        paddingTop: "20vh",
        scrollSnapAlign: "start",
      }}
    >
      <div
        ref={ref}
        style={
          {
            //backgroundColor: "#f4f4f4",
          }
        }
      >
        <div
          style={{
            //flexDirection: "row",
            alignContent: "center",
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {/*id === 3 && (
            <img
              src={`/assets/landing/project${id}-2.png`}
              style={{
                width: isMobile ? "50%" : "30%",
                //objectFit: "contain",
                objectFit: "cover",
              }}
            />
            )*/}
          <img
            src={`/assets/landing/project${id}.png`}
            style={{
              scrollSnapAlign: "start",
              width: isMobile ? "50%" : "30%",
              //objectFit: "contain",
              objectFit: "cover",
            }}
          />
        </div>
      </div>

      {
        //isMobile ? (
        <div
          style={{
            transform: "translateY(-250px)",
          }}
        >
          <div
            style={{
              paddingLeft: 16,
              fontSize: 24,
              fontWeight: "bold",
              color:
                id === 1
                  ? "#430760"
                  : id === 2
                  ? PALETTE.COLOR_MAIN
                  : id === 3
                  ? "#3877C3"
                  : "#D90071",
            }}
          >
            {id === 1
              ? "광야클럽"
              : id === 2
              ? "로위헤어"
              : id === 3
              ? "뽐스튜디오"
              : "화물잇고"}
          </div>
          <div
            style={{
              paddingLeft: 16,
              fontWeight: "bold",

              //justifyContent: "flex-start",
              alignItems: "flex-start",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <img
              //unoptimized={true}
              src={`/assets/landing/logo${id}.png`}
              width={0}
              height={0}
              sizes="100vw"
              style={{
                objectFit: "contain",
                width: "auto",
                //minWidth: 100,
                height: 40,
              }}
              alt="샘플"
            ></img>
          </div>
          <div
            style={{
              width: "40%",
              paddingLeft: 16,
              fontWeight: "bold",
              fontSize: isMobile ? 16 : 24,
              whiteSpace: "wrap",
            }}
          >
            {id === 1
              ? "SM 엔터테이먼트 팬 소통 플랫폼"
              : id === 2
              ? "시리즈 A 30억 투자유치 성공한 공유 미용실 플랫폼"
              : id === 3
              ? "유튜브 쇼츠에 추가수익을 주는 음원사"
              : "화물 운송 중개를 위한 차주,화주,주선사 플랫폼"}
          </div>
          <div
            style={{
              marginTop: 10,
              cursor: "pointer",
              paddingLeft: 16,
              fontSize: 14,
              fontWeight: "bold",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            VIEW MORE
            <img
              //unoptimized={true}
              src={"/assets/landing/right.png"}
              width={0}
              height={0}
              sizes="100vw"
              style={{
                width: 20,
                height: 20,
                marginLeft: 10,
              }}
              alt="샘플"
            ></img>
          </div>
        </div>
        /*) : (
        <motion.div style={{ y }}>
          <div
            style={{
              paddingLeft: 16,
              fontSize: 24,
              fontWeight: "bold",
              color:
                id === 1
                  ? "#430760"
                  : id === 2
                  ? PALETTE.COLOR_MAIN
                  : id === 3
                  ? "#3877C3"
                  : "#D90071",
            }}
          >
            {id === 1
              ? "광야클럽"
              : id === 2
              ? "로위헤어"
              : id === 3
              ? "뽐스튜디오"
              : "화물잇고"}
          </div>
          <div
            style={{
              paddingLeft: 16,
              fontWeight: "bold",

              //justifyContent: "flex-start",
              alignItems: "flex-start",
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <img
              //unoptimized={true}
              src={`/assets/landing/logo${id}.png`}
              width={0}
              height={0}
              sizes="100vw"
              style={{
                objectFit: "contain",
                width: "auto",
                //minWidth: 100,
                height: 40,
              }}
              alt="샘플"
            ></img>
          </div>
          <div
            style={{
              width: "40%",
              paddingLeft: 16,
              fontWeight: "bold",
              fontSize: isMobile ? 16 : 24,
              whiteSpace: "wrap",
            }}
          >
            {id === 1
              ? "SM 엔터테이먼트 팬 소통 플랫폼"
              : id === 2
              ? "시리즈 A 30억 투자유치 성공한 공유 미용실 플랫폼"
              : id === 3
              ? "유튜브 쇼츠에 추가수익을 주는 음원사"
              : "화물 운송 중개를 위한 차주,화주,주선사 플랫폼"}
          </div>
          <div
            style={{
              marginTop: 10,
              cursor: "pointer",
              paddingLeft: 16,
              fontSize: 14,
              fontWeight: "bold",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            VIEW MORE
            <img
              //unoptimized={true}
              src={"/assets/landing/right.png"}
              width={0}
              height={0}
              sizes="100vw"
              style={{
                width: 20,
                height: 20,
                marginLeft: 10,
              }}
              alt="샘플"
            ></img>
          </div>
        </motion.div>
            )*/
      }
    </section>
  );
}

export default function Home() {
  const [isMobileChk, setIsMobileChk] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });
  const isPc = useMediaQuery({
    query: "(min-width:1024px)",
  });
  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)",
  });
  useEffect(() => {
    // mobile 쿼리로 인해 값이 바뀔 때 수행
    if (isMobile) setIsMobileChk(true);
    else setIsMobileChk(true);
  }, [isMobile]);

  const { scrollYProgress } = useScroll();

  const videoRef1: any = useRef<HTMLVideoElement>(null);
  const videoRef2: any = useRef<HTMLVideoElement>(null);
  const videoRef3: any = useRef<HTMLVideoElement>(null);
  const videoRef4: any = useRef<HTMLVideoElement>(null);

  const videoRefRun: any = useRef<HTMLVideoElement>(null);

  const pRef: any = useRef(null);

  useEffect(() => {
    try {
      if (videoRefRun?.current?.readyState > 4) {
        videoRefRun?.current?.play();
      }
    } catch (err) {}
  }, [videoRefRun]);
  useEffect(() => {
    try {
      if (videoRef1?.current?.readyState > 4) {
        videoRef1?.current?.play();
      }
    } catch (err) {}
  }, [videoRef1]);
  useEffect(() => {
    try {
      if (videoRef2?.current?.readyState > 4) {
        videoRef2?.current?.play();
      }
    } catch (err) {}
  }, [videoRef2]);
  useEffect(() => {
    try {
      if (videoRef3?.current?.readyState > 4) {
        videoRef3?.current?.play();
      }
    } catch (err) {}
  }, [videoRef3]);
  useEffect(() => {
    try {
      if (videoRef4?.current?.readyState > 4) {
        videoRef4?.current?.play();
      }
    } catch (err) {}
  }, [videoRef4]);

  const registerVideo = (boundId: any, videoId: any) => {
    let bound: any = document.querySelector(boundId);
    let video: any = document.querySelector(videoId);
    const scrollVideo = () => {
      if (video?.duration) {
        const distanceFromTop =
          window.scrollY + bound.getBoundingClientRect().top;
        const rawPercentScrolled =
          (window.scrollY - distanceFromTop) /
          (bound.scrollHeight - window.innerHeight);
        const percentScrolled = Math.min(Math.max(rawPercentScrolled, 0), 1);

        video.currentTime = video.duration * percentScrolled;
      }
      requestAnimationFrame(scrollVideo);
    };
    requestAnimationFrame(scrollVideo);
  };
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  });

  const text1: any = useRef(null);
  const text2: any = useRef(null);

  const texts = [
    "핀테크",
    "채팅",
    "영상통화",
    "소개팅 어플",
    "크롤링",
    "스트리밍",
    "이커머스",
    "디비추출",
    ":)",
  ];

  const morphTime = 1;
  const cooldownTime = 0.25;

  let textIndex = texts.length - 1;
  let time: any = new Date();
  let morph = 0;
  let cooldown = cooldownTime;

  function doMorph() {
    morph -= cooldown;
    cooldown = 0;

    let fraction = morph / morphTime;

    if (fraction > 1) {
      cooldown = cooldownTime;
      fraction = 1;
    }

    setMorph(fraction);
  }

  function setMorph(fraction: any) {
    text2.current.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
    text2.current.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

    fraction = 1 - fraction;
    text1.current.style.filter = `blur(${Math.min(8 / fraction - 8, 100)}px)`;
    text1.current.style.opacity = `${Math.pow(fraction, 0.4) * 100}%`;

    text1.current.textContent = texts[textIndex % texts.length];
    text2.current.textContent = texts[(textIndex + 1) % texts.length];
  }

  function doCooldown() {
    morph = 0;

    text2.current.style.filter = "";
    text2.current.style.opacity = "100%";

    text1.current.style.filter = "";
    text1.current.style.opacity = "0%";
  }

  function animate() {
    requestAnimationFrame(animate);

    let newTime: any = new Date();
    let shouldIncrementIndex = cooldown > 0;
    let dt = (newTime - time) / 1000;
    time = newTime;

    cooldown -= dt;

    if (cooldown <= 0) {
      if (shouldIncrementIndex) {
        textIndex++;
      }

      if (text1?.current && text2?.current) doMorph();
    } else {
      doCooldown();
    }
  }

  useEffect(() => {
    try {
      //registerVideo("#bound-one", "#bound-one video");
      //registerVideo("#bound-two", "#bound-two video");
      //registerVideo("#bound-three", "#bound-three video");
      //registerVideo("#bound-four", "#bound-four video");
    } catch (err) {}
  }, [mounted]);

  useEffect(() => {
    if (text1?.current && text2?.current) {
      text1.current.textContent = texts[textIndex % texts.length];
      text2.current.textContent = texts[(textIndex + 1) % texts.length];
      animate();
    }
  }, [mounted, text1, text2]);

  const outerDivRef: any = useRef();

  const stop = useRef(false);

  useEffect(() => {
    const wheelHandler = (e: any) => {
      const { deltaY } = e;
      //const { scrollTop } = outerDivRef.current; // 스크롤 위쪽 끝부분 위치
      const pageHeight = window.innerHeight; // 화면 세로길이, 100vh와 같습니다.
      const scrollTop = window.pageYOffset;
      if (stop.current) {
        return;
      }

      //window.alert(`${pageHeight}, ${scrollTop}`);

      if (deltaY > 0) {
        // 스크롤 내릴 때
        if (scrollTop >= 0 && scrollTop < pageHeight * 0.7) {
          //현재 1페이지
          e.preventDefault();

          stop.current = true;
          setTimeout(() => {
            stop.current = false;
          }, 2000);

          console.log("현재 1페이지, down");
          window?.scrollTo({
            top: pageHeight * 0.7,
            left: 0,
            behavior: "smooth",
          });
        } else if (
          scrollTop >= pageHeight * 0.7 &&
          scrollTop < pageHeight * 1.2
        ) {
          //현재 2페이지
          e.preventDefault();
          stop.current = true;
          setTimeout(() => {
            stop.current = false;
          }, 2000);
          console.log("현재 2페이지, down");
          window?.scrollTo({
            top: pageHeight * 1.2,
            left: 0,
            behavior: "smooth",
          });
        } else {
          // 현재 3페이지
          return;
        }
      } else {
      }
    };

    if (mounted && outerDivRef?.current) {
      const outerDivRefCurrent = outerDivRef?.current;

      //outerDivRefCurrent?.addEventListener("wheel", wheelHandler);
      return () => {
        //outerDivRefCurrent?.removeEventListener("wheel", wheelHandler);
      };
    }
  }, [outerDivRef?.current, mounted]);

  return isMobileChk ? (
    <main className="AppBody">
      <Link
        href={"/contact"}
        style={{
          textDecoration: "none",
          position: "fixed",
          zIndex: 100,
          bottom: "10vh",
          right: isMobile ? 16 : "10vw",
        }}
      >
        <video
          ref={videoRef1}
          controls={false}
          playsInline={true}
          autoPlay={true}
          muted={true}
          loop={true}
          style={{
            width: 70,
            height: 70,
            borderRadius: 100,
            objectFit: "cover",
            position: "absolute",
            zIndex: -1,
            right: -10,
            bottom: -10,
          }}
          src={"/assets/landing/firstBG.mp4"}
        ></video>
        <div
          style={{
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <div
            className="teko-Logo"
            style={{
              color: "white",
              fontSize: 18,
              textDecorationLine: "none",
            }}
          >
            CONTACT
          </div>
          <div
            className="teko-Logo"
            style={{
              color: "white",
              fontSize: 18,
              marginTop: -10,
              textDecorationLine: "none",
            }}
          >
            US
          </div>
        </div>
      </Link>
      <div
        style={{
          height: "100vh",
          overflowX: "hidden",
          overflowY: "scroll",
          scrollSnapType: "y mandatory",
        }}
      >
        <div className="landing" ref={outerDivRef}>
          {/*1*/}
          <div
            className="box"
            style={{
              scrollSnapAlign: "start",
              display: "flex",
              zIndex: 3,
            }}
          >
            <div className="wave -one"></div>
            <div className="wave -two"></div>
            <div className="wave -three"></div>
            <div className="landing">
              <div
                className="landing-inner"
                style={{
                  zIndex: 2,
                }}
              >
                <div
                  style={{
                    flexDirection: isMobile ? "column-reverse" : "row",
                    width: "100%",
                    height: "80vh",
                  }}
                >
                  <div
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      width: isMobile ? "100%" : "40%",
                      height: "80vh",
                      justifyContent: isMobile ? "flex-start" : "center",
                      alignContent: "center",
                      alignItems: isMobile ? "flex-start" : "center",
                      //backgroundColor: "red",
                      color: "white",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          flexDirection: isMobile ? "row" : "column",
                          width: "100%",
                          alignContent: "center",
                          alignItems: isMobile ? "center" : "flex-start",
                          marginTop: isMobile ? 0 : 0,
                          marginBottom: isMobile ? 50 : 0,
                        }}
                      >
                        <img
                          //unoptimized={true}
                          src={"/assets/landing/boot.png"}
                          width={0}
                          height={0}
                          sizes="100vw"
                          style={{
                            width: isMobile ? 60 : 250,
                            height: isMobile ? 60 : 250,
                            marginRight: 10,
                          }}
                          alt="샘플"
                        ></img>
                        <div
                          className="teko-Logo"
                          style={{
                            fontWeight: "bold",
                            color: "white",
                            fontSize: isMobile ? 80 : 100,
                          }}
                        >
                          CodeArt
                        </div>
                      </div>
                      <div
                        style={{
                          marginTop: isMobile ? -60 : -25,
                          fontSize: 20,
                          color: "#a4a4a4",
                        }}
                      >
                        Adding art to code
                      </div>
                      <div
                        style={{
                          marginTop: isMobile ? 20 : 40,
                          color: "white",
                          fontSize: 20,
                        }}
                      >
                        MVP 부터 실서비스 까지 사용가능한
                      </div>
                      <div
                        style={{
                          color: "white",
                          fontSize: 20,
                          marginTop: 5,
                          marginBottom: 5,
                          fontWeight: "bold",
                        }}
                      >
                        웹,어플,서버,인프라,자동화
                      </div>
                      <div
                        style={{
                          color: "white",
                          fontSize: 20,
                        }}
                      >
                        분야의 개발을 해드립니다.
                      </div>
                      <div
                        style={{
                          marginTop: isMobile ? 50 : 100,
                          width: "100%",
                          borderRadius: 100,
                          alignItems: "center",
                          alignContent: "center",
                          justifyContent: "center",
                          //backgroundColor: "rgba(0,0,0,0.1)",
                        }}
                      >
                        <img
                          //unoptimized={true}
                          src={"/assets/landing/intro3.png"}
                          width={0}
                          height={0}
                          sizes="100vw"
                          style={{
                            width: "100%",
                            height: "100%",
                          }}
                          alt="샘플"
                        ></img>
                      </div>
                    </div>
                  </div>
                  <div
                    //ref={pRef}
                    style={{
                      zIndex: 3,
                      width: isMobile ? "100%" : "60%",
                      height: isMobile ? "50vh" : "80vh",
                      alignItems: "flex-end",
                    }}
                  >
                    <div
                      style={{
                        height: "100%",
                        justifyContent: "flex-start",
                      }}
                    >
                      <img
                        //unoptimized={true}
                        src={"/assets/landing/introa.png"}
                        style={{
                          width: isMobile ? "100vw" : "auto",
                          height: "45vh",
                          //maxWidth: isMobile? "100vw": pRef?.current?.clientWidth,
                          objectFit: "cover",
                        }}
                        alt="샘플"
                      ></img>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="animated-title"
              style={{
                transform: "translateY(-5vh)",
              }}
            >
              <div
                className="track"
                style={{
                  flexDirection: "row",
                }}
              >
                <div
                  className="content teko-Logo"
                  style={{
                    color: "white",
                    textShadow: "1px 1px 3px white",
                    //transform: "rotate(10deg)",
                    fontWeight: "bold",
                    fontSize: 50,
                  }}
                >
                  &nbsp;Code and art combine to create new results &nbsp;We
                  produce the best results.&nbsp;Please scroll through our story
                  to become your development partner.&nbsp;The best choice in
                  the web, app, server, infrastructure, and automation fields is
                  code art!&nbsp;
                </div>
              </div>
            </div>
          </div>
          {/*1*/}
          <div
            style={{
              scrollSnapAlign: isMobile ? "none" : "start",
              display: "flex",
              width: "100%",
              transform: "translateY(-100vh)",
              background: "#f4f4f4",
              height: "150vh",
              zIndex: 0,
              paddingTop: "100vh",
              transformOrigin: "50% 48%",
              //borderRadius: "100px",
              borderBottomRightRadius: "200vh",
              //transform-origin: 50% 48%;
              //border-radius: 43%;
              marginBottom: "-80vh",
            }}
          >
            <div className="landing">
              <div
                className="landing-inner"
                style={{
                  scrollSnapAlign: isMobile ? "start" : "none",
                }}
              >
                <div
                  style={{
                    alignContent: "center",
                    alignItems: "center",
                    flexDirection: isMobile ? "column" : "row",
                    position: "sticky",
                  }}
                >
                  <div
                    style={{
                      marginTop: isMobile ? 100 : 0,
                      paddingLeft: isMobile ? 16 : 0,
                      paddingRight: isMobile ? 16 : 0,
                      width: isMobile ? "100%" : "50%",
                      alignContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: isMobile ? 20 : 28,
                        marginBottom: 20,
                      }}
                    >
                      "플랫폼 사업의 시작과 끝의 동반자"
                    </div>
                    <div
                      style={{
                        color: "#838383",
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      안녕하세요 대표님의 예비 개발 파트너
                    </div>
                    <div
                      style={{
                        color: "#838383",
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      CodeArt가 가장 중요하게 생각하는것은
                    </div>
                    <div
                      style={{
                        color: "#838383",
                        fontSize: isMobile ? 12 : 16,
                        marginBottom: 20,
                      }}
                    >
                      MVP를 넘어 실 사용이 가능한 플랫폼을 만들수 있는지 입니다.
                    </div>
                    <div
                      style={{
                        fontWeight: "bold",
                        fontSize: isMobile ? 18 : 16,
                        marginBottom: 20,
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                      }}
                    >
                      단순 개발과{" "}
                      <span
                        style={{
                          marginLeft: isMobile ? 4 : 10,
                          marginRight: isMobile ? 4 : 10,
                          color: PALETTE.COLOR_MAIN,
                        }}
                      >
                        실 운영
                      </span>
                      은 엄청난 차이가 있습니다.
                    </div>
                    <div
                      style={{
                        flexDirection: "row",
                        alignContent: "center",
                        alignItems: "center",
                        fontSize: isMobile ? 16 : 20,
                      }}
                    >
                      예시를 한번 보겠습니다!
                    </div>
                    <motion.img
                      style={{
                        position: "absolute",
                        right: "5vw",
                        top: "-2vh",
                        width: "10vw",
                        height: "10vw",
                        maxWidth: 80,
                        maxHeight: 80,
                      }}
                      src="/assets/landing/3d1.png"
                      animate={{
                        scale: [1, 1.1, 1.2, 1.1, 1],
                      }}
                      transition={{
                        ease: "linear",
                        duration: 5,
                        x: { duration: 1 },
                        repeat: Infinity,
                        repeatDelay: 1,
                      }}
                    />
                    <motion.img
                      style={{
                        position: "absolute",
                        left: "5vw",
                        top: "40vh",
                        maxWidth: 80,
                        maxHeight: 80,
                        width: "10vw",
                        height: "10vw",
                      }}
                      src="/assets/landing/3d2.png"
                      animate={{
                        scale: [1, 1.1, 1.2, 1.1, 1],
                      }}
                      transition={{
                        ease: "linear",
                        duration: 5,
                        x: { duration: 1 },
                        repeat: Infinity,
                        repeatDelay: 1,
                      }}
                    />
                    <motion.img
                      style={{
                        position: "absolute",
                        right: "5vw",
                        top: "50vh",
                        width: "10vw",
                        height: "10vw",
                        maxWidth: 80,
                        maxHeight: 80,
                      }}
                      src={"/assets/landing/3d3.png"}
                      animate={{
                        scale: [1, 1.1, 1.2, 1.1, 1],
                      }}
                      transition={{
                        ease: "linear",
                        duration: 5,
                        x: { duration: 1 },
                        repeat: Infinity,
                        repeatDelay: 1,
                      }}
                    />
                    {isMobile && (
                      <img
                        //unoptimized={true}
                        src={"/assets/landing/phone.png"}
                        width={0}
                        height={0}
                        sizes="100vw"
                        style={{
                          zIndex: -1,
                          width: "auto",
                          height: "25%",
                          objectFit: "contain",
                          marginLeft: "10vw",
                          marginTop: 20,

                          //position: "absolute",
                          //right: "5vw",
                          //top: "40vh",
                        }}
                        alt="샘플"
                      ></img>
                    )}
                  </div>

                  {!isMobile && (
                    <div
                      style={{
                        paddingLeft: isMobile ? 16 : 0,
                        paddingRight: isMobile ? 16 : 0,
                        width: isMobile ? "100%" : "50%",
                      }}
                    >
                      <img
                        //unoptimized={true}
                        src={"/assets/landing/phone.png"}
                        width={0}
                        height={0}
                        sizes="100vw"
                        style={{
                          width: "auto",
                          height: "80%",
                          objectFit: "contain",
                        }}
                        alt="샘플"
                      ></img>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/*1*/}
          {/*
          <div
            style={{
              scrollSnapAlign: "start",
              //display: "flex",
              display: "block",
              width: "100vw",
              height: isMobile ? "150vh" : "100vh",
              overflowY: "scroll",
              background: "white",
              //overscrollBehavior: "none",
              //WebkitOverflowScrolling: "touch",
              //overscroll Behavior: "none",
            }}
          >
            
            <div
              id="bound-one"
              className="scroll-bound"
              style={{
                height: "500vh",
              }}
            >
              <div className="contentV">
                <div
                  style={{
                    zIndex: 1,
                    backgroundColor: "rgba(0,0,0,0.2)",
                    padding: 40,
                    width: isMobile ? "auto" : "470px",
                    paddingLeft: 20,
                    paddingRight: 20,
                    //position: "absolute",
                    //left: "10vw",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    clipPath:
                      "polygon(100% 0%, 100% 75%, 75% 100%, 0 100%, 0% 50%, 0 0)",
                    //borderTopRightRadius: 150,
                    //borderBottomLeftRadius: 150,
                  }}
                >
                  <div
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: isMobile ? 14 : 18,
                    }}
                  >
                    그림에서 스케치는 기초라는 역할을 해줍니다.
                  </div>
                  <div
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: isMobile ? 14 : 18,
                    }}
                  >
                    이 기초라는 기둥을 어떻게 설계했는지에 따라서
                  </div>
                  <div
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      fontSize: isMobile ? 14 : 18,
                    }}
                  >
                    이후의 작업물의 결과가 결정됩니다.
                  </div>
                </div>
                <video
                  ref={videoRef}
                  style={{
                    maxWidth: 1100,
                    width: "100%",
                  }}
                  controls={false}
                  playsInline={true}
                  autoPlay={true}
                  muted={true}
                  //@ts-ignore
                  preload={true}
                >
                  <source src="/assets/landing/pencil.mp4" type="video/mp4" />
                </video>
              </div>
            </div>
          </div>
                */}
        </div>
        <div>
          <div
            //id="bound-one"
            style={{
              height: "100vh",
            }}
          >
            <div
              className="contentV"
              style={{
                scrollSnapAlign: "start",
              }}
            >
              <div
                style={{
                  zIndex: 1,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  padding: 40,
                  width: isMobile ? "auto" : "470px",
                  paddingLeft: 20,
                  paddingRight: 20,
                  //position: "absolute",
                  //left: "10vw",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  clipPath:
                    "polygon(100% 0%, 100% 75%, 75% 100%, 0 100%, 0% 50%, 0 0)",
                  //borderTopRightRadius: 150,
                  //borderBottomLeftRadius: 150,
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  그림에서 스케치는 기초라는 역할을 해줍니다.
                </div>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  이 기초라는 기둥을 어떻게 설계했는지에 따라서
                </div>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  이후의 작업물의 결과가 결정됩니다.
                </div>
              </div>
              <video
                ref={videoRef2}
                controls={false}
                playsInline={true}
                autoPlay={true}
                muted={true}
                loop={true}
                style={{
                  maxWidth: 1100,
                  width: "100%",
                  marginBottom: "30vh",
                }}
                src={"/assets/landing/pencil.mp4"}
              ></video>
            </div>
          </div>
          <div
            //id="bound-one"
            style={{
              height: "100vh",
            }}
          >
            <div
              className="contentV"
              style={{
                scrollSnapAlign: "start",
              }}
            >
              <div
                style={{
                  zIndex: 1,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  padding: 40,
                  width: isMobile ? "auto" : "470px",
                  paddingLeft: 20,
                  paddingRight: 20,
                  //position: "absolute",
                  //left: "10vw",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  clipPath:
                    "polygon(100% 0%, 100% 75%, 75% 100%, 0 100%, 0% 50%, 0 0)",
                  //borderTopRightRadius: 150,
                  //borderBottomLeftRadius: 150,
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  MVP를 넘어 실 운영에도 쓰일 플랫폼은
                </div>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  튼튼한 기초 설계(db,인프라)와
                </div>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  효율적인 코드가 뒷받침 되야합니다.
                </div>
              </div>
              <video
                ref={videoRef3}
                controls={false}
                playsInline={true}
                autoPlay={true}
                muted={true}
                loop={true}
                style={{
                  maxWidth: 1100,
                  width: "100%",
                  marginBottom: "30vh",
                }}
                src={"/assets/landing/pencil2.mp4"}
              ></video>
            </div>
          </div>
          <div
            //id="bound-one"
            style={{
              height: "100vh",
              //marginBottom: "50vh",
            }}
          >
            <div
              className="contentV"
              style={{
                scrollSnapAlign: "start",
              }}
            >
              <div
                style={{
                  zIndex: 1,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  padding: 40,
                  width: isMobile ? "auto" : "470px",
                  paddingLeft: 20,
                  paddingRight: 20,
                  //position: "absolute",
                  //left: "10vw",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  clipPath:
                    "polygon(100% 0%, 100% 75%, 75% 100%, 0 100%, 0% 50%, 0 0)",
                  //borderTopRightRadius: 150,
                  //borderBottomLeftRadius: 150,
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  CodeArt는 현재 두개의 자사 서비스 플랫폼을
                </div>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  운영중으로 MAU 10만, 월매출 1-2억을
                </div>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  내며 얻은 노하우와 함께하고 있습니다.
                </div>
              </div>
              <video
                ref={videoRef4}
                controls={false}
                playsInline={true}
                autoPlay={true}
                muted={true}
                loop={true}
                style={{
                  maxWidth: 1100,
                  width: "100%",
                  marginBottom: "30vh",
                }}
                src={"/assets/landing/pencil4.mp4"}
              ></video>
            </div>
            <div
              className="contentV"
              style={{
                scrollSnapAlign: "start",
              }}
            >
              <div
                style={{
                  zIndex: 1,
                  backgroundColor: "rgba(0,0,0,0.2)",
                  padding: 40,
                  width: isMobile ? "auto" : "470px",
                  paddingLeft: 20,
                  paddingRight: 20,
                  //position: "absolute",
                  //left: "10vw",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  clipPath:
                    "polygon(100% 0%, 100% 75%, 75% 100%, 0 100%, 0% 50%, 0 0)",
                  //borderTopRightRadius: 150,
                  //borderBottomLeftRadius: 150,
                  //marginBottom: "50vh",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  “플랫폼 사업의 시작과 끝의 동반자”
                </div>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  MVP를 넘어 실 사용이 가능한 플랫폼
                </div>
                <div
                  style={{
                    color: "white",
                    fontWeight: "bold",
                    fontSize: isMobile ? 14 : 18,
                  }}
                >
                  CodeArt는 대표님의 마라톤에 지원군이 되겠습니다.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*1*/}
        <div
          className="landing"
          style={{
            scrollSnapAlign: "start",
            marginTop: "40vh",
            position: "sticky",
            background: PALETTE.COLOR_MAIN,
          }}
        >
          <div
            className="landing-inner"
            style={{
              background: PALETTE.COLOR_MAIN,
            }}
          >
            <div
              style={{
                height: isMobile ? "110vh" : "100vh",
                paddingBottom: isMobile ? "10vh" : "30vh",
                background: PALETTE.COLOR_MAIN,
                paddingLeft: 16,
                paddingRight: 16,
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <motion.img
                style={{
                  position: "absolute",
                  right: "5vw",
                  top: "-2vh",
                  width: "10vw",
                  height: "10vw",
                  maxWidth: 80,
                  maxHeight: 80,
                }}
                src="/assets/landing/3d1.png"
                animate={{
                  scale: [1, 1.1, 1.2, 1.1, 1],
                }}
                transition={{
                  ease: "linear",
                  duration: 5,
                  x: { duration: 1 },
                  repeat: Infinity,
                  repeatDelay: 1,
                }}
              />
              <motion.img
                style={{
                  position: "absolute",
                  left: "5vw",
                  top: "40vh",
                  maxWidth: 80,
                  maxHeight: 80,
                  width: "10vw",
                  height: "10vw",
                }}
                src="/assets/landing/3d2.png"
                animate={{
                  scale: [1, 1.1, 1.2, 1.1, 1],
                }}
                transition={{
                  ease: "linear",
                  duration: 5,
                  x: { duration: 1 },
                  repeat: Infinity,
                  repeatDelay: 1,
                }}
              />
              <motion.img
                style={{
                  position: "absolute",
                  right: "5vw",
                  top: "50vh",
                  width: "10vw",
                  height: "10vw",
                  maxWidth: 80,
                  maxHeight: 80,
                }}
                src="/assets/landing/3d3.png"
                animate={{
                  scale: [1, 1.1, 1.2, 1.1, 1],
                }}
                transition={{
                  ease: "linear",
                  duration: 5,
                  x: { duration: 1 },
                  repeat: Infinity,
                  repeatDelay: 1,
                }}
              />
              <div
                style={{
                  fontWeight: "bold",
                  color: "white",
                  fontSize: isMobile ? 24 : 40,
                  marginBottom: 20,
                }}
              >
                "특히 해당분야 개발에 강합니다!"
              </div>
              <div
                id="container01"
                style={{
                  width: "100%",
                  backgroundColor: "red",
                }}
              >
                <span
                  id="text1"
                  ref={text1}
                  style={{
                    width: "100%",
                    color: "white",
                    fontSize: isMobile ? 24 : 45,
                  }}
                ></span>
                <span
                  id="text2"
                  ref={text2}
                  style={{
                    width: "100%",
                    color: "white",
                    fontSize: isMobile ? 24 : 45,
                  }}
                ></span>
              </div>
              <svg
                id="filters"
                style={{
                  height: isMobile ? 45 : 65,
                }}
              >
                <defs>
                  <filter id="threshold">
                    <feColorMatrix
                      in="SourceGraphic"
                      type="matrix"
                      values="1 0 0 0 0
                  0 1 0 0 0
                  0 0 1 0 0
                  0 0 0 255 -140"
                    />
                  </filter>
                </defs>
              </svg>
              <div
                style={{
                  //color: "#838383",
                  fontSize: isMobile ? 16 : 20,
                  marginBottom: isMobile ? 0 : 20,
                }}
              >
                {"기능이 까다롭거나 복잡한 프로젝트에 강합니다 :)"}
              </div>

              <div
                style={{
                  width: "100%",
                  marginTop: isMobile ? 30 : 50,
                  marginBottom: 100,
                  flexDirection: isMobile ? "column" : "row",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginBottom: isMobile ? 10 : 0,
                    width: isMobile ? "100%" : "50%",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    //unoptimized={true}
                    src={"/assets/landing/book.png"}
                    width={0}
                    height={0}
                    sizes="100vw"
                    style={{
                      width: "100%",
                      height: "100%",
                      maxWidth: isMobile ? 100 : 300,
                    }}
                    alt="대표자 책"
                  ></img>
                  <div
                    style={{
                      color: "#696B69",
                      fontSize: isMobile ? 12 : 16,
                    }}
                  >
                    {"(대표자 지음)"}
                  </div>
                </div>
                <div
                  style={{
                    width: isMobile ? "100%" : "50%",
                    height: "100%",
                    justifyContent: "center",
                    paddingLeft: 16,
                  }}
                >
                  <div
                    style={{
                      fontWeight: "bold",
                      color: "white",
                      fontSize: isMobile ? 16 : 28,
                      marginBottom: isMobile ? 20 : 50,
                    }}
                  >
                    회사 주요 기술 스택
                  </div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        // color: "white",
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      웹 개발
                    </div>
                    <div
                      style={{
                        // color: "#838383",
                        color: "#838383",
                        marginBottom: 5,
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      html,css,javascript,react.js,next.js,typescript,webrtc
                    </div>

                    <div
                      style={{
                        // color: "white",
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      어플 개발
                    </div>
                    <div
                      style={{
                        color: "#838383",
                        marginBottom: 5,
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      kotlin,swift,react-native,typescript,webrtc
                    </div>

                    <div
                      style={{
                        // color: "white",
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      백엔드
                    </div>
                    <div
                      style={{
                        color: "#838383",
                        marginBottom: 5,
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      node.js,express,redis,mysql,socket.io,typescript
                    </div>

                    <div
                      style={{
                        //color: "white",
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      인프라
                    </div>
                    <div
                      style={{
                        color: "#838383",
                        marginBottom: 5,
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      ubuntu , aws (ec2,rds,s3,cloudfront,elastic cache,elb,
                      autoscaling etc)
                    </div>

                    <div
                      style={{
                        // color: "white",
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      자동화
                    </div>
                    <div
                      style={{
                        color: "#838383",
                        marginBottom: 5,
                        fontSize: isMobile ? 12 : 16,
                      }}
                    >
                      burp suite, puppeteer , axios
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "50%",
              height: "25vh",
              zIndex: 1,
              background: "#A2D0C8",
              borderTopRightRadius: "100%",
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "70%",
              height: "15vh",
              background: "#A2D0C8",
              borderTopLeftRadius: "100%",
              borderTopRightRadius: "100%",
              zIndex: 1,
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              bottom: 0,
              left: 0,
              width: "50%",
              height: "20vh",
              background: "white",
              //borderTopLeftRadius: "100%",
              borderTopRightRadius: "100%",
              border: 100,
              zIndex: 1,
            }}
          ></div>

          <div
            style={{
              position: "absolute",
              bottom: 0,
              right: 0,
              width: "70%",
              height: "10vh",
              background: "white",
              borderTopLeftRadius: "100%",
              borderTopRightRadius: "100%",
              zIndex: 1,
            }}
          ></div>
        </div>

        {/*1*/}
        <div className="landing">
          <div
            className="landing-inner"
            style={
              {
                //scrollSnapAlign: "start",
              }
            }
          >
            <div
              style={{
                height: "50vh",
              }}
            ></div>

            <div
              style={{
                zIndex: 3,

                //backdropFilter: "blur(20px)",
              }}
            >
              {[1, 2, 3, 4].map((image) => (
                <ImageMotion id={image} />
              ))}
            </div>
          </div>
        </div>
        {/*1*/}
        <div
          className="landing"
          style={{
            scrollSnapAlign: "center",
            width: "100%",
          }}
        >
          <div className="landing-inner">
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                height: "100vh",
                width: "100%",
                marginTop: -250,
                zIndex: 100,
                justifyContent: "center",
                //background: "red",
                paddingTop: "5vh",
                paddingBottom: "5vh",
              }}
            >
              <div
                style={{
                  fontSize: isMobile ? 16 : 24,
                  marginBottom: 10,
                }}
              >
                CodeArt 와 함께 세상에 걸작을 그려봐요.
              </div>

              <Link
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  textDecoration: "none",
                }}
                href={"/contact"}
              >
                <div
                  style={{
                    fontSize: isMobile ? 40 : 100,
                    fontWeight: "bold",
                  }}
                >
                  CONTACT US
                </div>
                <img
                  src={"/assets/landing/right.png"}
                  width={0}
                  height={0}
                  sizes="100vw"
                  style={{
                    width: isMobile ? 40 : 100,
                    height: isMobile ? 40 : 100,
                    marginLeft: isMobile ? 10 : 40,
                  }}
                  alt="샘플"
                ></img>
              </Link>
            </div>
          </div>
        </div>
        <div
          className="landing"
          style={{
            scrollSnapAlign: "end",
            width: "100%",
            borderTop: "2px solid black",
          }}
        >
          <div className="landing-inner">
            <div
              style={{
                paddingLeft: 16,
                paddingRight: 16,
                height: "40vh",
                width: "100%",
                paddingTop: "5vh",
                paddingBottom: "5vh",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    marginRight: isMobile ? 10 : 20,
                    fontSize: isMobile ? 20 : 36,
                  }}
                  onClick={() => {
                    window.open("https://www.instagram.com/code.art.official");
                  }}
                >
                  INSTAGRAM
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    marginRight: isMobile ? 10 : 20,
                    fontSize: isMobile ? 20 : 36,
                  }}
                >
                  ARTICLE
                </div>
                <div
                  style={{
                    cursor: "pointer",
                    fontWeight: "bold",
                    marginRight: isMobile ? 10 : 20,
                    fontSize: isMobile ? 20 : 36,
                  }}
                >
                  CAREER
                </div>
              </div>
              <div
                style={{
                  flexDirection: "row",
                  alignContent: "center",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div
                    className="teko-Logo"
                    style={{
                      fontSize: 18,
                      marginBottom: 2,
                    }}
                  >
                    contact
                  </div>
                  <div
                    className="teko-Logo"
                    style={{
                      fontSize: 18,
                      fontWeight: "bold",
                    }}
                  >
                    codeart.im@gmail.com
                  </div>
                </div>
              </div>
              <div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                  }}
                >
                  서울특별시 영등포구 국회대로36길 6-1, 2층 엠613호
                </div>
                <div
                  style={{
                    fontSize: 12,
                    marginBottom: 5,
                    fontWeight: "bold",
                  }}
                >
                  © (주)오타쿠세상을구하다 All rights reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <div></div>
  );
}
